<template>
  <div id="app">
    <Headers/>
    <div class="mtop"></div>
    <transition name="fade">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>

import Headers from './components/Header';


export default {
  name: 'app',
  components: {
    Headers,
  }
}
</script>

<style lang="less">
  .mtop{
    height: 90px;
  }
  .fade {

    &-enter-active, &-leave-active {
      transition: all .3s
    }
    &-enter, &-leave-active {
      opacity: 0
    }
 }
</style>
