import Vue from 'vue'
import { Button,Message, Pagination,Collapse,CollapseItem,Form,FormItem,Input} from 'element-ui'

Vue.use(Button)
Vue.use(Pagination)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.prototype.$message = Message

