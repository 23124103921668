import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import VueAwesomeSwiper  from "vue-awesome-swiper";
import axios from 'axios'
import Share from 'vue-social-share'
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import $ from 'jquery'
import VueAnimateOnScroll from 'vue-animate-onscroll'
import AMapLoader from "@amap/amap-jsapi-loader";


//全局公共样式
import './assets/css/global.css'
import './assets/css/responsive.css'
import "./assets/css/animate.min.css";
import "swiper/dist/css/swiper.css";
import 'vue-social-share/dist/client.css';


Vue.use(VueAwesomeSwiper)
Vue.config.productionTip = false
Vue.use(Share)
Vue.use(VueAnimateOnScroll)

AMapLoader.load({
  'key': 'd05f872844b80ea5decf4b101b11971c',           
  'version': '2.0',
  'plugins': ['AMap.Scale','AMap.GeoJSON']    
}).then((AMap) => {
  Vue.use(AMap)
})

//配置请求的根路径
axios.defaults.baseURL = 'https://gwadmin.anjilog.com/api/'
Vue.prototype.$http = axios

NProgress.configure({ showSpinner: false })
router.beforeEach((to, from, next) => {
    NProgress.start();
    next()
});
 
router.afterEach(transition => {
	NProgress.done();
});

new Vue({
  router,
  $,
  render: h => h(App)
}).$mount('#app')

