<template>
      <header class="header clearfix">
        <div class="header__wrap inner clearfix">
          <a class="logo" href="/"><img src="../assets/logo.jpg" alt=""/></a>
          <div class="header__right clearfix">
            <ul class="header__navs clearfix">
              <li v-for="(item,index) in navsData" :key="item.id"  >
                <router-link v-if="index == 0" to="/home" :class="index==n?'router-link-exact-active':''" >{{item.name}}</router-link>
                <router-link v-else :to="item.path" :class="index==n?'router-link-exact-active':''" @click="classToggle(index)">{{item.name}}</router-link>
                <ol class="navs__menu">
                  <li v-for="sub in item.subItems" :key="sub.id">
                    <router-link :to="`${item.path}${sub.path}`">{{sub.name}}</router-link>
                  </li>
                </ol>
              </li>
            </ul>
            
            <!-- <div class="header__oa">
              <a target="_block" :href="oaData.wd_url">网上订单</a><a target="_block" :href="oaData.oa_url">oa</a>
            </div> -->
          </div>
          <div class="menubtn" :class="{ active: navigateDat }" @click="handleToggle">
            <span></span>
          </div>
        </div>
        
        <div class="navigate" :class="{ show: navigateDat }" @click="handleToggle">
          <ul class="navigate-list">
            <li v-for="(item1,index) in navsData" :key="item1.id">
                <router-link v-if="index == 0" to="/home">首页</router-link>
                <router-link v-else :to="item1.path" >{{item1.name}}</router-link>
                <ol class="navigate__menu">
                  <li v-for="sub in item1.subItems" :key="sub.id">
                    <router-link :to="`${item1.path}${sub.path}`" >{{sub.name}}</router-link>
                  </li>
                </ol>
              </li>
          </ul>
          <!-- <div class="header__oa">
              <a target="_block" :href="oaData.wd_url">网上订单</a><a target="_block" :href="oaData.oa_url">oa</a>
            </div> -->
        </div>
        
      </header>
</template>

<script>
export default {
  data(){
    return {
      navsData:[
         
      ],
      oaData:[],
      n:0,
      navigateDat: false,
    }
  },
  methods: {
        async getNavs(){
            const {data: res} = await this.$http.post("getCate");
            const {data: res1} = await this.$http.post("getSetInfo");
            this.navsData = res.data;
            this.oaData = res1.data;
            console.log(res)
        },
        handleToggle(){
          this.navigateDat = !this.navigateDat;
        },
        classToggle(index){
          this.n = index;
        }
    },
    mounted() {
        this.getNavs();

    }
}
</script>

<style lang="less">
  .header{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    padding: 11px 0 0;
    z-index: 99;
    background: #fff;
    box-shadow: 0 0 5px rgba(0,0,0,0.3);
    .logo{
      display: block;
      position: relative;
      z-index: 4;
      float: left;
      width: 359px;
      img{
        display: block;
        width: 100%;
        height: auto;
      }
    }
  }
  .header__right{
    float: right;
  }
  .header__navs{
    float: left;
    margin-top: 26px;
    margin-right: 2px;
    >li{
      position: relative;
      padding: 0 23px;
      line-height: 0;
      float: left;
      &:last-child{
        &:before{
          display: none;
        }
      }
      &:before{
        position: absolute;
        right: 0;
        top: 2px;
        content: "";
        width: 1px;
        height: 10px;
        background: url("../assets/line.png") center no-repeat;
      }
      &:hover{
          .navs__menu{
            opacity: 1;
            visibility: visible;
            transform: translateY(0px);
            pointer-events: visible;
          }
          >a{
            color: #e50012;
            font-weight: bold;
            &:before{
              opacity: 1;
              visibility: visible;
              bottom: 20px;
            }
          }
      }
      // &.selected{
      //     >a{
      //       color: #e50012;
      //       font-weight: bold;
      //       &:before{
      //         opacity: 1;
      //         visibility: visible;
      //         bottom: 20px;
      //       }
      //     }
      // }
      >a{
        display: inline-block;
        padding-bottom: 39px;
        position: relative;
        cursor: pointer;
        color: #333333;
        font-size: 14px;
        line-height: 1;
        &:before{
          position: absolute;
          left: 50%;
          margin-left: -5px;
          bottom: 25px;
          content: "";
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-bottom: 6px solid #e50012;
          opacity: 0;
          visibility: hidden;
          transition: 0.36s;
        }
        &.router-link-active{
          color: #e50012;
          font-weight: bold;
          &:before{
            opacity: 1;
            visibility: visible;
            bottom: 20px;
          }
        }
        
      }
    }
  }
  .navs__menu{
    position: absolute;
    left: -200px;
    top: 100%;
    padding-left: 16px;
    background: #e03021;
    width: 60vw;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    pointer-events: none;
    transition: 0.38s;
    z-index: 10;
    li{
      float: left;
      margin-right: 24px;
      a{
        display: inline-block;
        position: relative;
        color: #ffffff;
        font-size: 14px;
        height: 46px;
        line-height: 46px;
        &:hover{
          &:before{
            width: 100%;
            right: auto;
            left: 0;
          }
        }
        &:before{
          position: absolute;
          right: 0;
          bottom: 8px;
          content: "";
          width: 0;
          height: 1px;
          background: #fff;
          transition: 0.45s;
        }
      }
    }
  }
  .header__oa{
    float: left;
    margin-top: 18px;
    a{
      display: block;
      position: relative;
      z-index: 3;
      float: left;
      text-align: center;
      width: 90px;
      height: 28px;
      line-height: 28px;
      border-radius: 14px;
      color: #fff;
      font-size: 12px;
      background: linear-gradient(to right, #d92d1e 0%, #fb773f 100%);
      &:before{
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        content: "";
        width: 100%;
        height: 100%;
        background: #e03021;
        border-radius: 14px;
        transition: 0.45s;
        opacity: 0;
        visibility: hidden;
      }
      &:hover{
        &:before{
          opacity: 1;
          visibility: visible;
        }
      }
      &:last-child{
        margin-left: 12px;
        width: 28px;
        text-transform: uppercase;
      }
    }
  }
  .navigate{
    position:fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding-bottom: 40px;
    overflow-y: auto;
    background: #fff;
    display: none;
    box-sizing: border-box;
    transition: 0.88s;
    opacity: 0;
    visibility: hidden;
    left: 100%;
    &.show{
      opacity: 1;
      visibility: visible;
      left: 0;
    }
    .header__oa{
      display: block;
      margin-left: 5%;
    }
  }
  .navigate-list{
    margin: 100px 5% 30px;
    >li{
      margin-bottom: 20px;
      >a{
        display: inline-block;
        line-height: 1.8;
        font-weight: bold;
        font-size: 18px;
        color: #333333;
      }
    }
  }
  .navigate__menu{
    margin-top: 10px;
    display: flex;
    li{
      margin-right: 20px;
      &:last-child{
        margin-right: 0;
      }
      a{
        display: inline-block;
        color: #666;
        font-size: 16px;
      }
    }
  }
</style>