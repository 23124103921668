import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
const Home = () => import(/* webpackChunkName: "group-home" */ '../views/index.vue')

// import About from '../views/About.vue'
const About = () => import(/* webpackChunkName: "group-about" */ '../views/About.vue')

const zbcg = () => import(/* webpackChunkName: "group-about" */ '../components/about/zbcg.vue')

const zbcgDetail = () => import(/* webpackChunkName: "group-about" */ '../components/about/zbcgDetail.vue')

// import Business from '../views/Business.vue'
const Business = () => import(/* webpackChunkName: "group-business" */ '../views/Business.vue')

//import companyNews from '../views/News.vue'
const companyNews = () => import(/* webpackChunkName: "group-news" */ '../views/News.vue')

// import PartyNews from '../views/Party.vue'
const PartyNews = () => import(/* webpackChunkName: "group-news" */ '../views/Party.vue')

// import RecuitM from '../views/Recuit.vue'
const RecuitM = () => import(/* webpackChunkName: "group-recuit" */ '../views/Recuit.vue')

// import ContactUs from '../views/ContactUs.vue'
const ContactUs = () => import(/* webpackChunkName: "group-contact" */ '../views/ContactUs.vue')

// import Introduct from '../components/about/Introduct.vue'
const Introduct = () => import(/* webpackChunkName: "group-about" */ '../components/about/Introduct.vue')

// import culture from '../components/about/Culture.vue'
const culture = () => import(/* webpackChunkName: "group-about" */ '../components/about/Culture.vue')

const Organize = () => import(/* webpackChunkName: "group-about" */ '../components/about/Organize.vue')

const djlh = () => import(/* webpackChunkName: "group-about" */ '../components/about/djlh.vue')

const djlhDetail = () => import(/* webpackChunkName: "group-about" */ '../components/about/djlhDetail.vue')

const qtjl = () => import(/* webpackChunkName: "group-about" */ '../components/about/qtjl.vue')

const qtjlDetail = () => import(/* webpackChunkName: "group-about" */ '../components/about/qtjlDetail.vue')

// import subsidiary from '../components/about/subsidiary.vue'
const subsidiary = () => import(/* webpackChunkName: "group-about" */ '../components/about/subsidiary.vue')

// import honor from '../components/about/Honor.vue'
const honor = () => import(/* webpackChunkName: "group-about" */ '../components/about/Honor.vue')

// import businessDetail from '../components/business/businessDetail.vue'
const businessDetail = () => import(/* webpackChunkName: "group-business" */ '../components/business/businessDetail.vue')

// import News from '../components/news/CompanyNew.vue'
const News = () => import(/* webpackChunkName: "group-news" */ '../components/news/CompanyNew.vue')

const zgNews = () => import(/* webpackChunkName: "group-news" */ '../components/news/zigongsixinwen.vue')

// import IndustryNews from '../components/news/IndustryNews.vue'
const IndustryNews = () => import(/* webpackChunkName: "group-news" */ '../components/news/IndustryNews.vue')

// import newsDetail from '../components/news/NewsDetail.vue'
const newsDetail = () => import(/* webpackChunkName: "group-news" */ '../components/news/NewsDetail.vue')

// import Party from '../components/party/party.vue'
const Party = () => import(/* webpackChunkName: "group-news" */ '../components/party/party.vue')

// import Partyact from '../components/party/partyact.vue'
const Partyact = () => import(/* webpackChunkName: "group-news" */ '../components/party/partyact.vue')

// import PartyDetail from '../components/party/PartyDetail.vue'
const PartyDetail = () => import(/* webpackChunkName: "group-news" */ '../components/party/PartyDetail.vue')

// import Concept from '../components/recruit/concept.vue'
const Concept = () => import(/* webpackChunkName: "group-recuit" */ '../components/recruit/concept.vue')

// import Recruit from '../components/recruit/recruit.vue'
const Recruit = () => import(/* webpackChunkName: "group-recuit" */ '../components/recruit/recruit.vue')

const xyzp = () => import(/* webpackChunkName: "group-recuit" */ '../components/recruit/xiaoyuanzhaopin.vue')
const lygs = () => import(/* webpackChunkName: "group-recuit" */ '../components/recruit/lygs.vue')
const lygsDetail = () => import(/* webpackChunkName: "group-news" */ '../components/recruit/lygsDetail.vue')

// import Style from '../components/recruit/style.vue'
const Style = () => import(/* webpackChunkName: "group-recuit" */ '../components/recruit/style.vue')

// import Contact from '../components/contact/contact.vue'
const Contact = () => import(/* webpackChunkName: "group-contact" */ '../components/contact/contact.vue')

// import Message from '../components/contact/message.vue'
const Message = () => import(/* webpackChunkName: "group-contact" */ '../components/contact/message.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    meta: {
      requiresAuth: 0
    }
  },
  {
    path: '/about',
    component: About,
    redirect:'/about/about',
    children: [
      {
        path: '/about/about',
        component: Introduct,
        
      },
      {
        path: '/about/culture',
        component: culture
      },
      {
        path: '/about/party',
        component: djlh
      },
      {
        path: '/party/:cid',
        component: djlhDetail
      },
      {
        path: '/about/partyact',
        component: qtjl
      },
      {
        path: '/partyact/:cid',
        component: qtjlDetail
      },
      {
        path: '/about/subsidiary',
        component: subsidiary
      },
      {
        path: '/about/organize',
        component: Organize
      },
      {
        path: '/about/zhaobiaocaigou',
        component: zbcg
      },
      {
        path: '/zbcgDetail/:cid',
        name: 'zbcgDetail',
        component: zbcgDetail,
      },
      {
        path: '/about/honor',
        component: honor
      }
    ]
  },
  {
    path: '/business',
    name: 'business',
    component: Business,
  },
  {
    path: '/business/businessDetail/:cid',
    name: 'businessDetail',
    component: businessDetail,
  },
  {
    path: '/companynews',
    component: companyNews,
    redirect:'/companynews/companynews',
    children:[
      {
        path: '/companynews/companynews',
        component: News, 
      },
      {
        path: '/companynews/zigongsixinwen',
        component: zgNews
      },
      {
        path: '/companynews/industrynews',
        component: IndustryNews
      },
      {
        path: '/companynews/:cid',
        name: 'newsDetail',
        component: newsDetail,
      },
    ]
  },
  {
    path: '/party',
    component: PartyNews,
    redirect:'/party/party',
    children:[
      {
        path: '/party/party',
        component: Party,
      },
      {
        path: '/party/partyact',
        component: Partyact,
      },
      {
        path: '/party/:cid',
        name: 'PartyDetail',
        component: PartyDetail,
      },
    ]
  },
  {
    path: '/concept',
    component: RecuitM,
    redirect:'/concept/concept',
    children:[
      {
        path: '/concept/concept/',
        component: Concept,
      },{
        path: '/concept/recruit',
        component: Recruit,
      },{
        path: '/concept/style',
        component: Style,
      },{
        path: '/concept/xiaoyuanzhaopin',
        component: xyzp,
      },{
        path: '/concept/lygs',
        component: lygs,
      },
      {
        path: '/lygsDetail/:cid',
        name: 'lygsDetail',
        component: lygsDetail,
      }
    ]
  },
  {
    path: '/contact',
    component: ContactUs,
    redirect:'/contact/contact',
    children:[
      {
        path: '/contact/contact',
        component: Contact,
      },{
        path: '/contact/message',
        component: Message,
      }
    ]
  }
  
]

const router = new VueRouter({
  routes,
  mode: 'history',
});

router.afterEach(() => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
});

export default router
